import * as React from "react";
import PropTypes from "prop-types";
import { GatsbyImage } from "gatsby-plugin-image";

const GatsbyImageWrapper = ({ image, alt, loading, width, height, className }) => {
  if (!image || !image.localFile) {
    console.warn("FAILED IMAGE:", image);  // Keep logging but don't break build
    return <div className="fallback-image" style={{ width, height, backgroundColor: "#ccc" }} />;
  }

  // Handle SVG and GIF separately
  if (image.localFile.ext === ".svg" || image.localFile.ext === ".gif") {
    return (
      <img
        src={image.localFile.publicURL}
        alt={alt || image.altText || ""}
        loading={loading ?? "lazy"}
        width={width || (image.mediaDetails?.width)}
        height={height || (image.mediaDetails?.height)}
        className={className}
      />
    );
  }

  // Default Gatsby Image
  return (
    <GatsbyImage
      image={image.localFile.childImageSharp?.gatsbyImageData}
      alt={alt || image.altText || ""}
      loading={loading ?? "lazy"}
      className={className}
    />
  );
};

GatsbyImageWrapper.propTypes = {
  image: PropTypes.object,
  alt: PropTypes.string,
  loading: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default GatsbyImageWrapper;
